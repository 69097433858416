<template>
    <div>
        <nf-breadcrumb :breadList="breadList"></nf-breadcrumb>
        <div class='wrapper'>
            <div v-if='list.length > 0'>
                <!-- <div v-for='(item, index) in list' :key='index' class='listItem'> -->
                <router-link tag='div' :to="{ path: '/policyMatchingDetail', query:{id:item.id} }" v-for='(item, index) in list' :key='index' class='listItem'>
                    <div class='title'>{{item.title}}</div>
                    <div class='issueOrganization'>
                        <span>{{item.issueOrganization}}</span>
                        <span>{{item.issueTime}}</span>
                    </div>
                    <div class='abstracts ellipsis'>{{item.abstracts}}</div>
                </router-link>
                <!-- </div> -->
                <pagination v-if='total > param.pageSize' @pageChange="pageChange" :total='total' :currentPage='param.pageNum' :pageSize='param.pageSize'></pagination>
            </div>
            <div v-else>
                <no-data></no-data>
            </div>
        </div>
    </div>
</template>
<script>
import nfBreadcrumb from '@/component/breadcrumb/breadcrumb';
import pagination from '@/component/pagination/index.vue';
import noData from '@/component/noData/index.vue';
export default {
     components: {
        nfBreadcrumb,
        pagination,
        noData
    },
    data() {
        return {
            param: {
                pageNum: 1,
                pageSize: 10
            },
            total: 0,
            breadList: [{
                name: '热点功能'
            }, {
                name: '政策匹配'
            }],
            list: []
        };
    },
    mounted() {
        this.getList();
    },
    methods: {
        async getList() {
            const api = this.$fetchApi.policyList.api;
            const data = await this.$fetchData.fetchPost(this.param, api, 'json');
            if (data.code === '200') {
                this.list = data.result.rows;
                this.total = data.result.total;
            }

        },
        pageChange (item) {
            this.param.pageNum = item.page_index;
            this.param.pageSize = item.page_limit;
            this.getList(); // 改变页码，重新渲染页面
        }
    }  
};
</script>
<style lang="less" scoped>
    .wrapper{
        width:1180px;
        margin:0 auto;
        padding-bottom: 108px;
        .title{
            font-size: 18px;
            color: #333;
            font-weight: bold;
        }
        .title::before{
            width: 6px;
            height: 12px;
            background-color: #265ae1;
            border-radius: 3px;
            display: inline-block;
            content: '';
            margin-right:10px;
        }
        .listItem{
            padding:24px 0 20px 0;
            border-bottom: 1px dashed #d9d9d9;
        }
        .issueOrganization{
            font-size:14px;
            color: #999999;
            padding:18px 0 15px 0;
            margin-left:20px;
            span{
                margin-right:32px;
                display: inline-block;
            }
        }
        .abstracts{
            color: #5c5c5c;
            font-size:16px;
            margin-left:20px;
        }
    }
</style>